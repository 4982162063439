import React, { ReactNode, useMemo } from "react";
import "./Col.scss";

export interface IColProps {
	children: ReactNode;
	gap?: string;
	scroll?: boolean;
	verticalAlign?: string;
	horizontalAlign?: string;
	flex?: string;
	height?: string;
    width?: string;
}

export function Col({ children, gap, verticalAlign, horizontalAlign, flex, height, scroll, width }: IColProps) {
	return (
		<div
			className={`__sage-col-container vertical-${verticalAlign} horizontal-${horizontalAlign}`}
			style={{ gap, flex, height, overflowY: scroll ? "scroll" : "", width }}
		>
			{children}
		</div>
	);
}
